var Form = (function(){
  
  function _focusEvent(){
    var $field = $('.ninja-forms-field');
    
    if( $field.length ) {
      $field.on('focusin', function(){
        $(this).parent().addClass('focus');
      });
      $field.on('focusout', function(){
        $(this).parent().removeClass('focus');
      });
    }
  }
  
  function init() {
    _focusEvent();
  }

  return { init: init };
  
})();