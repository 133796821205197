(function($) {
  
  var bodyEl = $('body'),
      content = $( '.content-wrap' ),
      openbtn = $( '#primary-nav-open' ),
      closebtn = $( '#primary-nav-close' ),
      subMenuTrigger = $( '.has-sub-menu > a' ),
      subMenuClose = $( '.sub-menu-close' ),
      subMenu = $( '.sub-menu' ),
      isOpen = false;
  
  function init() {
    events();
  }
  
  function events() {
    
    openbtn.on( 'click', toggleMenu );
    if( closebtn ) {
      closebtn.on( 'click', toggleMenu );
    }

    // close the menu element if the target it´s not the menu element or one of its descendants.
    content.on( 'click', function(ev) {
      var target = ev.target;
      if( isOpen && target !== openbtn[0] ) {
        toggleMenu();
      }
    } );
    
  }
  
  function toggleMenu() {
    if( isOpen ) {
      bodyEl.removeClass('show-menu' );
    }
    else {
      bodyEl.addClass('show-menu');
    }
    isOpen = !isOpen;
  }
  
  subMenuTrigger.on('click', function(){
    $(this).parent().addClass('show-sub-menu');
  });
  
  subMenuClose.on('click', function(){
    $(this).parents('.has-sub-menu').removeClass('show-sub-menu');
  });
  
  init();

})(jQuery);