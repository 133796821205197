/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        Form.init();
        
        // Thumb overlay
        $('.entry-grid--overlay, .entry-grid__overlay').on('click', function(e){
          e.preventDefault();
          $(this).toggleClass('show-overlay');
        });
        
      }
    },
    // Home page
    'home': {
      init: function() {
        Slider.init();
        Carousel.init();
      }
    },
    'page_template_template_contacts_php': {
      init: function(){
        // Google Maps Init
        function initMap() {
          var el = document.getElementById('map'),
              mLat = el.getAttribute('data-map-lat'),
              mLng = el.getAttribute('data-map-lng'),
              latLng = {lat: parseFloat(mLat), lng: parseFloat(mLng)};
        
          var map = new google.maps.Map(el, {
            center: latLng,
            zoom: 13,
            scrollwheel: false
          });
          
          var marker = new google.maps.Marker({
            position: latLng,
            map: map
          });
        }
        google.maps.event.addDomListener(window, 'load', initMap);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.