(function($) {
  var style = document.createElement('div').style,
    tx = $.fn.cycle.transitions,
    supported = style.transform !== undefined ||
      style.MozTransform !== undefined ||
      style.webkitTransform !== undefined ||
      style.oTransform !== undefined ||
      style.msTransform !== undefined;

  if ( supported ) {
    tx.cssTransition = {
      preInit: function( opts ) {
        opts.container.css({
          '-webkit-transform': 'translate3d(0,0,0)',
          '-moz-transform': 'translate3d(0,0,0)',
          '-ms-transform': 'translate3d(0,0,0)',
          '-o-transform': 'translate3d(0,0,0)',
          'transform': 'translate3d(0,0,0)',
          '-webkit-transition': 'all +'+opts.manualSpeed+'ms ease-out',
          '-moz-transition': 'all +'+opts.manualSpeed+'ms ease-out',
          '-ms-transition': 'all +'+opts.manualSpeed+'ms ease-out',
          '-o-transition': 'all +'+opts.manualSpeed+'ms ease-out',
          'transition': 'all +'+opts.manualSpeed+'ms ease-out',
          'overflow': 'hidden'
        });
          
        opts.slides.css({
          '-webkit-transform': 'translate3d(0,0,0)',
          '-moz-transform': 'translate3d(0,0,0)',
          '-ms-transform': 'translate3d(0,0,0)',
          '-o-transform': 'translate3d(0,0,0)',
          'transform': 'translate3d(0,0,0)'
        });
      },
      before: tx.scrollHorz.before
    };
  } else {
    tx.cssTransition = tx.scrollHorz;
  }
})(jQuery);

var Slider = (function($){

  function init() {
    
    var $element = $('#slider');
    
    $element.on( 'cycle-initialized', function() {
      $element.find('.slider__loader').removeClass('is-active');
    });
    
    $element.on( 'cycle-update-view', function(event, optionHash, slideOptionsHash, currentSlideEl) {
      if (!currentSlideEl.getAttribute('data-cycle-label')) {
        slideOptionsHash.captionTemplate = '<div class="slider__caption"><span class="slider__caption__title">{{title}}</span><span class="slider__caption__content">{{content}}</span></div>';
      } else { 
        slideOptionsHash.captionTemplate = '<div class="slider__caption"><span class="slider__caption__title">{{title}}</span><span class="slider__caption__content">{{content}}</span><span class="slider__caption__cta"><a href="{{link}}" title="{{label}}">{{label}}</a></span></div>';
      }
    });
    
    $element.cycle({
      timeout: 5000,
      log: false,
      fx: 'cssTransition',
      loader: 'wait',
      captionPlugin: 'caption2',
      slides: '.slider__slide',
      pager: '.slider__pager',
      pagerTemplate: '<span></span>',
      captionTemplate: '<div class="slider__caption"><span class="slider__caption__title">{{title}}</span><span class="slider__caption__content">{{content}}</span><span class="slider__caption__cta"><a href="{{link}}" title="{{label}}">{{label}}</a></span></div>',
      swipe: true,
      updateView: 1
    });

  }
  
  return {
    init: init
  };

})(jQuery);

var Carousel = (function($){
  
  var $elements = $('.carousel');
  
  function init() {
    
    $elements.cycle();
    
    setTimeout(function(){
      responsive();
    }, 50);

  }
  
  function responsive() {

     var  screenXS = 'screen and (max-width: 767px)',
          screenSM = 'screen and (min-width: 768px) and (max-width: 991px)',
          screenMD = 'screen and (min-width: 992px) and (max-width: 1199px)',
          screenLG = 'screen and (min-width: 1200px)',
          carouselVisible = null;
    
    // Extra small devices
    enquire.register(screenXS, {
      match : function() {
        $elements.each(function(index, value){

          var $this = $(value);
          if( $this.attr('data-cycle-carousel-visible-xs') ) {
            carouselVisible = parseInt( $this.attr('data-cycle-carousel-visible-xs') );
            $this.attr('data-cycle-carousel-visible', carouselVisible).cycle('reinit');
          }
          
        });
      }
    });
    
    // Small devices
    enquire.register(screenSM, {
      match : function() {
        $elements.each(function(index, value){

          var $this = $(value);
          if( $this.attr('data-cycle-carousel-visible-sm') ) {
            carouselVisible = parseInt( $this.attr('data-cycle-carousel-visible-sm') );
            $this.attr('data-cycle-carousel-visible', carouselVisible).cycle('reinit');
          }
          
        });
      }
    });
    
    // Medium devices
    enquire.register(screenMD, {
      match : function() {
        $elements.each(function(index, value){

          var $this = $(value);
          if( $this.attr('data-cycle-carousel-visible-md') ) {
            carouselVisible = parseInt( $this.attr('data-cycle-carousel-visible-md') );
            $this.attr('data-cycle-carousel-visible', carouselVisible).cycle('reinit');
          }
          
        });
      }
    });
    
    // Large devices
    enquire.register(screenLG, {
      match : function() {
        $elements.each(function(index, value){

          var $this = $(value);
          if( $this.attr('data-cycle-carousel-visible-lg') ) {
            carouselVisible = parseInt( $this.attr('data-cycle-carousel-visible-lg') );
            $this.attr('data-cycle-carousel-visible', carouselVisible).cycle('reinit');
          }
          
        });
      }
    });
    
    
    
  }
  
  return {
    init: init
  };

})(jQuery);